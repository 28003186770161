<template>
	<div class="content">
		<Banner :data="banner" :showBreadcrumbs="true"></Banner>
		<WithLeftMenu :blocks="withLeftMenuContent" :additionalClass="'type3'"></WithLeftMenu>
		<section class="fullPageSectionContainer setMenuColor newForm" data-menu-color="light">
			<div class="fullPageSection">
				<div class="fullPageSection--back" style="background-color: #262E39"></div>
				<Feedback :type='2'></Feedback>
			</div>
		</section>
	</div>
</template>

<script>
	import	Banner from '@/components/app/case/Banner'
	import	WithLeftMenu from '@/components/app/WithLeftMenu'
	import 	projectsData from "@/assets/json/projects.json";
	import 	reviewsData from "@/assets/json/reviews.json";

	export default {
		metaInfo: {
			title: 'Создание корпоративного сайта медицинского центра Сердце | «‎Артрокетс»',
			meta: [
				{ vmid: 'description', name: 'description', content: 'Подробная информация о выполненном проекте и об этапах работ по созданию сайта для узкопрофильного медицинского центра в г.Казань' },
				{ vmid: 'og:title', property: 'og:title', content: 'Создание корпоративного сайта медицинского центра Сердце | «‎Артрокетс»' },
				{ vmid: 'og:description', property: 'og:description', content: 'Подробная информация о выполненном проекте и об этапах работ по созданию сайта для узкопрофильного медицинского центра в г.Казань' },
			],
		},
		data: () => ({
			banner: {
				title: 'Центр Сердце',
				titleType: "big",
				desc: 'Узкопрофильный медицинский центр в г.Казань',
				titleIsH1: true,
				// image: require(`@/assets/img/centrserdce/banner.jpg`),
				// mobileImage: require(`@/assets/img/centrserdce/mobileBanner.jpg`),
				bannerType: 'center centrSerdce',
				video: require(`@/assets/img_new/projectsSlider/serdce.mp4`),
				videoPoster: require(`@/assets/img_new/projectsSlider/serdce-poster.jpg`),
			},
			withLeftMenuContent: [
				{
					component: 'caseInfo',
					name: 'О проекте',
					nameType: "big",
					data: {
						desc: "Медицинский центр «Сердце» занимается профилактикой и лечением сердечно-сосудистых заболеваний. Клиент пришел к нам с запросом на редизайн сайта.",
						linkText: 'centrserdce.ru',
						linkHref: 'https://centrserdce.ru/',
						dateInfo: [
							"срок разработки: 2 месяца",
							"запуск: октябрь 2019 г."
						],
						info: [
							{
								title: "Задача",
								desc: "Создать современный сайт клиники. Выгодно представить услуги клиники, разместить информацию о врачах, с графиком их работы и возможностью онлайн-записи на прием. Сделать сайт  более привлекательным, удобным и функциональным для пользователей.",
								author: {
									name: "Юлия Токмакова",
									desc: "Директор МЦ Сердце",
									image: require(`@/assets/img/reviews/serdce.png`)
								}
							},{
								title: "Результат",
								desc: "Разработан имиджевый корпоративный сайт, с удобной навигацией и привлекательным дизайном. Теперь найти детальную информацию по услугам можно в 2 клика.Пользователи через сайт узнают об услугах, врачах, времени приема и записываются онлайн или по телефону. Сайт удобно просматривать на любом устройстве при  любом размере экрана.",
								author: {
									name: "Айрат Вагапов",
									desc: "Менеджер Артрокетс",
									image: require(`@/assets/img/centrserdce/author2.jpg`)
								}
							}
						]
					}
				},{
					component: 'contentBlockWithImage',
					name: 'Пользователи',
					nameType: "big",
					background: "#EFD2D4",
					noBorder: true,
					additionalClass: "hideImageInTablet",
					data: {
						data: [
							{
								component: 'text',
								additionalClass: 'margin2',
								text: "Мы провели анализ аудитории старого сайта клиники. Большая часть пользователей - это молодые люди от 25 до 34 лет (почти 64% входящего трафика). 11% это люди от 35 до 44 лет, чуть меньше 45-54 года. Пожилые люди старше 55+ составляют 1-2% от всех посетителей."
							},{
								component: 'percentageBlocks',
								additionalClass: 'margin',
								data:  [
									{
										percent: "64",
										desc: "Люди от 25 до 34 лет"
									},{
										percent: "11",
										desc: "Люди от 35 до 44 лет"
									},{
										percent: "23",
										desc: "Люди от 45 до 54 лет"
									},{
										percent: "2",
										desc: "Люди 55+ лет"
									}
								]
							},{
								component: 'textWithImageInTablet',
								text: "<p class='fs20 margin6'><b>По данным <a class='redLink' href='https://www.rbc.ru/technology_and_media/13/01/2020/5e1876549a7947210b5ef636#:~:text=%D0%9F%D1%80%D0%B8%D1%80%D0%BE%D1%81%D1%82%20%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82-%D0%B0%D1%83%D0%B4%D0%B8%D1%82%D0%BE%D1%80%D0%B8%D0%B8%20%D0%B2%202019%20%D0%B3%D0%BE%D0%B4%D1%83%20%D0%BE%D0%B1%D0%B5%D1%81%D0%BF%D0%B5%D1%87%D0%B8%D0%BB%D0%B8%20%D0%BF%D0%B5%D0%BD%D1%81%D0%B8%D0%BE%D0%BD%D0%B5%D1%80%D1%8B,-%D0%92%202019%20%D0%B3%D0%BE%D0%B4%D1%83&text=%D0%A7%D0%B8%D1%81%D0%BB%D0%BE%20%D0%BF%D0%BE%D0%BB%D1%8C%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D0%B5%D0%B9%20%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%BD%D0%B5%D1%82%D0%B0%20%D1%81%D1%80%D0%B5%D0%B4%D0%B8%20%D1%80%D0%BE%D1%81%D1%81%D0%B8%D1%8F%D0%BD,(%D0%B5%D1%81%D1%82%D1%8C%20%D0%B2%20%D1%80%D0%B0%D1%81%D0%BF%D0%BE%D1%80%D1%8F%D0%B6%D0%B5%D0%BD%D0%B8%D0%B8%20%D0%A0%D0%91%D0%9A).' target='_blank'>РБК</a></b></p> В 2019 году больше всего возросло количество пожилых пользователей интернета. Поэтому мы спроектировали сайт, ориентируясь на посетителях возрастных групп от 25 до 55+ лет.",
								image: require(`@/assets/img/centrserdce/rbk.png`)
							}
						],
						image: require(`@/assets/img/centrserdce/rbk.png`)
					}
				},{
					component: 'contentBlock',
					name: 'Дизайн идея',
					nameType: "big",
					noBorder: true,
					data: [
						{
							component: 'text',
							additionalClass: 'margin2',
							text: "Прежний сайт был оформлен с использованием холодных цветов. Был “не  дружелюбным” к пользователю - сложно было найти информацию, и, пользоваться им было неудобно.<br><br> Начали работу с создания визуальной концепции. Определили два чистых нейтральных цвета - белый и серый для основного оформления. Теплый красный цвет стал главным акцентным цветом - его используем для привлечения внимания на важной информации. Для изображений было решено использовать качественные  “живые” фотографии с пациентами  - так посетители сайта будут ассоциировать их с собой и образы станут запоминающимися.<br><br> За счет грамотного подбора цветов, качественных фотографий клиники и врачей  - сайт выделяется среди конкурентов."
						},{
							component: 'beforeAfterBlock',
							additionalClass: 'margin2',
							data: {
								type: "tablet",
								beforeImage: require(`@/assets/img/centrserdce/before.jpg`),
								afterImage: require(`@/assets/img/centrserdce/after.jpg`)
							}
						},{
							component: 'text',
							additionalClass: 'margin2',
							text: "Как только посетитель попадает на сайт, он в течение первых секунд формирует свое мнение о компании. Чтобы зацепить пользователя, мы с первого экрана доносим образ, в котором отражаем потребительский инсайт."
						},{
							component: 'chat',
							data: [
								{
									position: "left",
									backColor: "#E3E5E9",
									textColor: "black",
									name: "Потребительский инсайт",
									text: "Мне важно мое здоровье и здоровье моих близких"
								},{
									position: "right",
									backColor: "#B92227",
									textColor: "white",
									name: "Ответ на инсайт",
									text: "Нам доверяют самое ценное  - свое сердце"
								}
							]
						}
					]
				},{
					component: 'contentBlock',
					name: 'Главная страница',
					nameType: "big",
					noTopPadding: true,
					noBorder: true,
					data: [
						{
							component: 'siteScreens',
							additionalClass: 'margin2',
							data: {
								type: "type1",
								desktopImage: require(`@/assets/img/centrserdce/desktop.png`),
								mobileImage: require(`@/assets/img/centrserdce/mobile.png`),
								mobileImageInner: require(`@/assets/img/centrserdce/mobileFull.png`)
							}
						},{
							component: 'text',
							text: "Главная страница - это фундамент и отправная точка для более детального изучения предлагаемых услуг. Перед нами стояла задача - разместить на основной странице перечень услуг, краткую информацию о клинике, блок с врачами, отзывами, акциями и схемой проезда. Сделать все нужно было так, чтобы пользователь не запутался в избытке информации, нашел то, что ему нужно и провалился во внутренние разделы."
						}
					]
				},{
					component: 'contentBlock',
					name: 'Услуги',
					nameType: "big",
					background: "#EFD2D4",
					noBorder: true,
					data: [
						{
							component: 'text',
							additionalClass: "margin5",
							text: "На странице разместили все услуги в виде плиток, сгруппированных по основным направлениям компании: консультация, УЗИ, функциональная диагностика.<br><br> Для каждой услуги совместно с главным врачом клиники выделили показания к проведению - так клиенту будет проще разобраться в медицинских терминах и услугах. На странице со списком услуг как и на каждой странице сайта - в нижней части всегда размещается форма обратной связи, на случай если клиенту требуется помощь или же он готов записаться на прием.<br><br> Обязательно указываем цену - это положительно скажется на лидогенерации."
						},{
							component: 'images',
							additionalClass: "margin2",
							data: {
								imagesBlockClass: "threeCol withScroll",
								withScroll: true,
								images: [
									{
										image: require(`@/assets/img/centrserdce/uslugi2.png`)
									},{
										image: require(`@/assets/img/centrserdce/uslugi3.png`)
									},{
										image: require(`@/assets/img/centrserdce/uslugi4.png`)
									}
								]
							}
						},{
							component: 'text',
							additionalClass: "margin5",
							text: "Для удобства разработали “умный” фильтр для поиска по возрастной группе и видам услуг."
						},{
							component: 'images',
							data: {
								images: [
									{
										image: require(`@/assets/img/centrserdce/uslugi1.png`)
									}
								]
							}
						}
					]
				},{
					component: 'contentBlock',
					name: 'Врачи',
					nameType: "big",
					background: "#EFD2D4",
					noTopPadding: true,
					noBorder: true,
					data: [
						{
							component: 'text',
							additionalClass: "margin2",
							text: "Пациенту любого медучереждения важно, чтобы его здоровьем занимался профессиональный специалист. Мы сделали акцент на врачах: разместили информацию о стаже работы, образовании, сертификатах и навыках. Указали возраст пациентов, которых принимает каждый специалист клиники, часы приема и возможность онлайн-записи к врачу.<br><br><br> Заказчику порекомендовали провести фотосессию врачей в единой стилистике."
						},{
							component: 'images',
							data: {
								images: [
									{
										image: require(`@/assets/img/centrserdce/medics.png`)
									}
								]
							}
						}
					]
				},{
					component: 'contentBlock',
					name: 'Цены',
					nameType: "big",
					background: "#EFD2D4",
					noTopPadding: true,
					noBorder: true,
					data: [
						{
							component: 'text',
							additionalClass: "margin5",
							text: "Создали отдельную страницу с прейскурантом услуг. Разместили удобную таблицу для просмотра. Прайс можно скачать в виде PDF-файла."
						},{
							component: 'images',
							data: {
								images: [
									{
										image: require(`@/assets/img/centrserdce/prices.png`)
									}
								]
							}
						}
					]
				},{
					component: 'contentBlock',
					name: 'Блог медцентра',
					nameType: "big",
					background: "#EFD2D4",
					noTopPadding: true,
					noBottomPadding: true,
					noBorder: true,
					data: [
						{
							component: 'text',
							additionalClass: "margin3",
							text: "С помощью статей блога клиника будет доносить свои дополнительные преимущества. В качестве авторов каждой статьи выступают врачи медцентра - это дополнительно скажется на формировании доверия у клиентов. Каждая статья оптимизируется под поисковые запросы для получения дополнительного трафика из поисковых систем.<br><br> Разработали форму подписки на статьи. Выполнили интеграцию с Инстаграмм для получения новых подписчиков."
						},{
							component: 'siteScreens',
							data: {
								type: "type2",
								desktopImage: require(`@/assets/img/centrserdce/desktop2.png`),
								personImage: require(`@/assets/img/centrserdce/person.png`)
							}
						}
					]
				},{
					component: 'contentBlock',
					name: 'Все страницы',
					nameType: "big",
					noBorder: true,
					data: [
						{
							component: 'photoScroll',
							name: 'Прототипы',
							clickable: true,
							data: {
								text: "",
								imagesClass: "border18",
								images: [
									{
										title: "Главная",
										image: require(`@/assets/img/centrserdce/prototype/1.png`),
										fullImage: require(`@/assets/img/centrserdce/prototype/1_full.jpg`)
									},{
										title: "О компании",
										image: require(`@/assets/img/centrserdce/prototype/2.png`),
										fullImage: require(`@/assets/img/centrserdce/prototype/2_full.jpg`)
									},{
										title: "Услуги",
										image: require(`@/assets/img/centrserdce/prototype/3.png`),
										fullImage: require(`@/assets/img/centrserdce/prototype/3_full.jpg`)
									},{
										title: "Врачи",
										image: require(`@/assets/img/centrserdce/prototype/4.png`),
										fullImage: require(`@/assets/img/centrserdce/prototype/4_full.jpg`)
									},{
										title: "Врач внутренняя",
										image: require(`@/assets/img/centrserdce/prototype/5.png`),
										fullImage: require(`@/assets/img/centrserdce/prototype/5_full.jpg`)
									},{
										title: "Цены",
										image: require(`@/assets/img/centrserdce/prototype/6.png`),
										fullImage: require(`@/assets/img/centrserdce/prototype/6_full.jpg`)
									},{
										title: "404 страница",
										image: require(`@/assets/img/centrserdce/prototype/7.png`),
										fullImage: require(`@/assets/img/centrserdce/prototype/7_full.jpg`)
									},{
										title: "Контакты",
										image: require(`@/assets/img/centrserdce/prototype/8.png`),
										fullImage: require(`@/assets/img/centrserdce/prototype/8_full.jpg`)
									}
								]
							}
						}
					]
				},{
					component: 'centrSerdceAdaptiveDesign',
					name: 'Адаптив',
					nameType: "big",
					noTopPadding: true,
					noBorder: true,
					data:{
						upperText: "По данным Яндекс.Метрики, более 67% пользователей посещали “старый” сайт с мобильных устройств. Мы отдельно проработали дизайн как для мобильников, так и для планшетов. Сайт корректно отображается на любом устройстве, им удобно пользоваться независимо от размера экрана.",
						percentages: [
							{
								percent: "30,6",
								desc: "Desktop 1440 px.",
								type: "desktop",
								image: require(`@/assets/img/centrserdce/desktop3.png`)
							},{
								percent: "1,61",
								desc: "Tablet 768 px.",
								type: "tablet",
								image: require(`@/assets/img/centrserdce/tablet.png`)
							},{
								percent: "67,7",
								desc: "Mobile 320 px.",
								type: "mobile",
								image: require(`@/assets/img/centrserdce/mobile2.png`)
							}
						]
					}
				},{
					component: 'reviews',
					name: 'Отзыв клиента',
					nameType: "big",
					data: reviewsData,
					project: 'serdce'
				},{
					component: 'projects',
					name: 'Другие проекты',
					nameType: "big",
					quantity: 3,
					quantityTable: 3,
					quantityMobile: 3,
					smallElements: true,
					data: projectsData
				}
			]
		}),
		name: 'centrserdce',
		components: {
			Feedback: () => import('@/components/new/Forms/Feedback'),
			WithLeftMenu,
			Banner
		}
	}
</script>
